<template>
<tbody class="table-font" v-if="item!=null">
    <tr>
        <td>{{ ((currentpage-1) * noofrec) + (index+1) }}</td>
        <td  class="visible-lg"><a  href="#" @click="detail(item.user)">{{ item.user.generatedid }}</a></td>
        <td><a  href="#" >{{item.user.name}}</a></td> 
        <td><display-mobiles :mobiles="item.user.mobiles"></display-mobiles></td>
        <td><display-first-address :addresses="item.user.addressess"></display-first-address></td>
        <!-- <td>{{  moment(item.date).format("DD-MM-YYYY")}}</td> -->
        <td><i class="fa fa-calendar"></i> {{ moment(item.date).format('DD-MM-YYYY')}}</td>
        <td><i class="fa fa-calendar"></i> {{ item.user.scheduledate==null?'N.A':moment(item.user.scheduledate).format('DD-MM-YYYY')}}</td>
        <td style="width:14%">
            <template v-if="item.user.referraluser!=null">
                {{ item.user.referraluser.generatedid }}<br>
                {{item.user.referraluser.name}}
                <display-mobiles :mobiles="item.user.referraluser.mobiles"></display-mobiles>
            </template>
        </td>
        <td>{{item.user.creditlimit}}</td>
        <!-- <td >
            <button type="button" class="btn btn-prime btn-xs" style="margin-bottom:5px;"  @click="makedefaulter(item)">
                <div class="font-size:10px;" v-if="item.isdefaulter==0">Make Defaulter ({{item.customer_type}})</div>
                <div class="font-size:10px;" v-else>Remove</div>
            </button>
        </td> -->
        <td style="width:8%" :style="ratingcolor(item)">
            <span style="font-size:18px;text-align:center">{{item.user.customer_type}}</span>
        </td>
        <td><button class="badge btn-prime btn-xs" @click="showchats()">conversation</button></td>
    </tr>
    <tr v-if="visitcomments">
        <td colspan="10">
            <crm-conversation :item="item" ></crm-conversation>
        </td>
    </tr>
</tbody>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue';
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue';
import CrmConversation from './CrmConversation.vue';
import Constants from '../../components/utilities/Constants.vue';
export default {
    mixins:[Constants],
    props:['item','index','currentpage','noofrec'],
    components: {
    	DisplayFirstAddress,DisplayMobiles,
        CrmConversation
        },
    data() {
        return {
            moment:moment,
            visitcomments:false
        }
    },
    computed:{
			...mapGetters([
		     'viewno','loggedinuser',
		    ]),
        },
    methods: {
        showchats(){
            this.visitcomments=!this.visitcomments
        },
        ratingcolor(item){
            if(item.user.customer_type=='A'||item.user.customer_type=='B'){
                return "background:green;color:white;"
            }else if(item.user.customer_type=='C'){
                return "background:orange;color:white;"
            }else if(item.user.customer_type=='D' || item.user.isdefaulter==1){
                return "background:red;color:white;"
            }
        },
        detail(item){
            if(this.loginusercheck(183)){
                this.$store.commit('assignedititem',item)
                this.$store.commit('assignviewno',3)
            }else{
                alert("You Don't Have Right To Access")
            }
            
        },
        refresh(){
            this.$parent.refresh();
        },

    },
}
</script>